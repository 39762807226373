<template>
	<v-sheet class="customer" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{
								location.barcode
							}}</v-chip>
						</p>
						<v-spacer></v-spacer>

						<v-btn
							v-if="getPermission('property:update')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>
						<template v-if="getPermission('property:delete')">
							<v-btn
								v-if="!location.is_owner"
								v-on:click="doAction('delete')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-delete</v-icon>Delete</v-btn
							>
						</template>
						<template v-if="!location.is_owner">
							<template v-if="getPermission('property:update')">
								<v-btn
									v-if="location.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
								>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
								>
							</template>
						</template>
						<template v-if="getPermission('property:create')">
							<v-btn
								v-if="customerTab == 'address-book'"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Address</v-btn
							>
						</template>
					</div>
					<div class="d-flex">
						<div class="w-100 pr-3">
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Property # : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="location" object-key="barcode" label="barcode"></ShowValue
										></span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Property Name : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="location" object-key="name" label="name"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Postal Code : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue
													:object="location"
													object-key="address_postal_code"
													label="postal Code"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Customer Name : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="customer"
												object-key="display_name"
												label="customer Name"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Contact Person # : </span>
										<span class="fw-500">
											<ShowValue :object="contact_person" object-key="barcode" label="barcode"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Contact Person Name : </span>
										<span class="fw-500">
											<ShowValue
												:object="contact_person"
												object-key="display_name"
												label="contact person name"
											></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Customer # : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="customer" object-key="barcode" label="customer"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Address Line 1 : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="location"
												object-key="address_line_1"
												label="address line 1"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Address Line 2 : </span>
										<span class="fw-500">
											<ShowValue
												:object="location"
												object-key="address_line_1"
												label="address line 2"
											></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Country : </span>
										<span class="fw-500">
											<ShowValue
												:object="location"
												object-key="address_country"
												label="display_name"
											></ShowValue>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600">Location : </span>
										<span class="fw-500">
											<ShowValue :object="location" object-key="nest_location" label="Location"></ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="propertyTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#asset"> Asset </v-tab>
					<v-tab href="#asset-stock"> Asset Stock </v-tab>
					<v-tab href="#inventory"> Perishables </v-tab>
					<v-tab href="#comment"> Comment </v-tab>
					<v-tab href="#services" v-if="false"> Services </v-tab>
					<v-tab href="#file" v-if="false"> Files</v-tab>
					<v-tab href="#mail" v-if="false"> Mails </v-tab>
					<v-tab href="#event"> Logs </v-tab>
					<v-tabs-items v-model="propertyTab">
						<v-tab-item value="asset">
							<AssetListing
								v-if="location && location.id && propertyTab == 'asset'"
								class="mx-4"
								relation-type="location"
								:relation-type-id="location.id"
							></AssetListing>
						</v-tab-item>
						<v-tab-item value="asset-stock">
							<AssetStockListing
								v-if="location && location.id && propertyTab == 'asset-stock'"
								class="mx-4"
								relation-type="location"
								:relation-type-id="location.id"
							></AssetStockListing>
						</v-tab-item>
						<v-tab-item value="inventory">
							<InventoryListing
								v-if="location && location.id && propertyTab == 'inventory'"
								class="mx-4"
								relation-type="location"
								:relation-type-id="location.id"
							></InventoryListing>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								v-if="location && location.id && propertyTab == 'comment'"
								class="mx-4"
								type-text="Vendor"
								type="location"
								:type-uuid="uuid"
								relation-type="location"
								:relation-type-id="location.id"
							></Comments>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								v-if="propertyTab == 'file'"
								class="mx-4"
								type-text="Supplier Files"
								type="contact"
								:type-uuid="uuid"
							></Files>
						</v-tab-item>
						<v-tab-item value="event">
							<EventListing
								v-if="location && location.id && propertyTab == 'event'"
								relation-type="location"
								:relation-type-id="location.id"
								class="mx-4"
								type-text="Supplier"
								type="location"
								:type-uuid="uuid"
							></EventListing>
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>
			</div>
			<!-- <div>
				<div class="mx-4">
					<div class="overflow-y" style="max-height: calc(100vh - 250px)">
						<v-layout class="p-4 border-bottom-light-grey min-height-57px">
							<v-flex class="font-level-3-bold my-auto">
								<span class="detail-svg-icon mr-2">
									
									<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
								
								</span>
								Property Details
							</v-flex>
						</v-layout>
						<table width="100%" class="detail-table scattered-table">
							<tr>
								<th width="250px">Property #</th>
								<td>
									<ShowValue :object="location" object-key="barcode" label="customer #"></ShowValue>
								</td>
								<th width="250px">Postal Code</th>
								<td>
									<ShowValue
										:object="location"
										object-key="address_postal_code"
										label="Postal Code"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<th width="250px">Customer #</th>
								<td>
									<ShowValue :object="customer" object-key="barcode" label="company name"></ShowValue>
								</td>
								<th width="250px">Customer Name</th>
								<td>
									<ShowValue :object="customer" object-key="display_name" label="display name"></ShowValue>
								</td>
							</tr>
							<tr>
								<th width="250px">Contact Person #</th>
								<td>
									<ShowValue :object="contact_person" object-key="barcode" label="company name"></ShowValue>
								</td>
								<th width="250px">Contact Person Name</th>
								<td>
									<ShowValue
										:object="contact_person"
										object-key="display_name"
										label="display name"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<th width="250px">Address Line 1</th>
								<td>
									<ShowValue
										:object="location"
										object-key="address_line_1"
										label="Address Line 1"
									></ShowValue>
								</td>
								<th width="250px">Address Line 2</th>
								<td>
									<ShowValue
										:object="location"
										object-key="address_line_1"
										label="Address Line 1"
									></ShowValue>
								</td>
							</tr>
							<tr v-if="false">
								<th width="250px">Country</th>
								<td>
									<ShowValue :object="location" object-key="address_country" label="website"></ShowValue>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div> -->
			<DeleteTemplate
				type="Property"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					customer.activated
						? 'To preserve associated records, you could de-activate the Property instead.'
						: null
				"
			>
				<template v-if="customer.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Property"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Property"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetProperty } from "@/core/lib/contact.lib";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import Files from "@/view/components/Files";
import AssetListing from "@/view/components/AssetListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import InventoryListing from "@/view/components/InventoryListing";
import EventListing from "@/view/components/EventListing";
import Comments from "@/view/components/Comments";

export default {
	name: "customer-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			propertyTab: "asset",
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			contact_person: [],
			deleteURL: null,
			formValid: true,
			personModel: {},
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			customerTab: null,
			customer: {},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "property-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		getProperty() {
			GetProperty(this.uuid)
				.then((data) => {
					const customer = data.contact;
					this.location = data;
					this.barcode = data.barcode;
					this.customer = customer;
					this.contact_person = data.contact_person;
					this.deleteText = `#${data.barcode}`;
					this.deleteURL = `location/${this.uuid}`;
					this.activateText = `#${data.barcode}`;
					this.activateURL = `location/${this.uuid}/activate`;
					this.deactivateText = `#${data.barcode}`;
					this.deactivateURL = `location/${this.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Property", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		ShowValue,
		DeleteTemplate,
		ActivateTemplate,
		DeactivateTemplate,
		Files,
		EventListing,
		AssetListing,
		AssetStockListing,
		InventoryListing,
		Comments,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Property", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getProperty();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
